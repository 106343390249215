import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {EventText} from '../../../../components/members-page/components/styled-components/event-text'
import s from '../ticket-with-seating.scss'
import {AreaSeatInfoProps} from './interfaces'

export const AreaSeatInfo = ({ticket, seatInfo}: AreaSeatInfoProps) => {
  const {t} = useTranslation()

  return seatInfo.section ? (
    <div className={s.colSeatInfo} role="cell">
      <div className={s.firstSeatingCol}>
        <EventText extraClass={s.ticketDetailsHeader}>{t('order.seating.section')}</EventText>
        <EventText>{seatInfo.section}</EventText>
      </div>
      <div className={s.secondSeatingCol}>
        <div className={s.colSeating}>
          <EventText extraClass={s.ticketDetailsHeader}>{seatInfo.seatingInfo.primary.header}</EventText>
          <EventText>{seatInfo.seatingInfo.primary.text}</EventText>
        </div>
        {seatInfo.seatingInfo.secondary && (
          <div className={s.colSeating}>
            <EventText extraClass={s.ticketDetailsHeader}>{seatInfo.seatingInfo.secondary.header}</EventText>
            <EventText>{seatInfo.seatingInfo.secondary.text}</EventText>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={s.colSeatInfo} role="cell">
      <div className={s.colSeating}>
        <EventText extraClass={s.ticketDetailsHeader}>{t('order.seating.area')}</EventText>
        <EventText>{ticket.ticketDetails.areaName}</EventText>
      </div>
    </div>
  )
}

import {createSettingsParams} from '@wix/yoshi-flow-editor/tpa-settings'

export type ISettingsParams = {
  pageTitle: string
  description: string
  upcomingEventsTab: string
  pastEventsTab: string
  noUpcomingEvents: string
  noUpcomingEventsLink: string
  noUpcomingEventsLinkPageId: string
}

const settingsParams = createSettingsParams<ISettingsParams>({
  pageTitle: {
    getDefaultValue: () => undefined,
  },
  description: {
    getDefaultValue: () => undefined,
  },
  upcomingEventsTab: {
    getDefaultValue: () => undefined,
  },
  pastEventsTab: {
    getDefaultValue: () => undefined,
  },
  noUpcomingEvents: {
    getDefaultValue: () => undefined,
  },
  noUpcomingEventsLink: {
    getDefaultValue: () => undefined,
  },
  noUpcomingEventsLinkPageId: {
    getDefaultValue: () => undefined,
  },
})

export default settingsParams
